/* global smily */

class RentalsPromoted extends HTMLElement {
  constructor() {
    super();

    this.wrapper = this.querySelector('[data-content]');

    if (!this.wrapper) {
      throw new Error('Missing wrapper element with [data-content] attribute');
    }

    this.fetchRentals();
  }

  get template() {
    return this.getAttribute('data-template') || '<div class="col d-flex">#{content}</div>';
  }

  set template(value) {
    this.setAttribute('data-template', value);
  }

  get limit() {
    return parseInt(this.getAttribute('data-limit')) || 4;
  }

  set limit(value) {
    this.setAttribute('data-limit', value);
  }

  async fetchRentals() {
    // add cards placeholders
    this.wrapper.insertAdjacentHTML('beforeend', smily.RentalsCardsPlacholders(4, this.template));

    const data = await smily.rentalsList(
      { per_page: this.limit },
      smily.website.rentalsPromotedPath,
    );

    // remove cards placeholders
    this.wrapper.querySelectorAll('.card-placeholder').forEach((card) => card.parentElement.remove());

    if (data.rentals.length) {
      this.wrapper.insertAdjacentHTML(
        'beforeend',
        smily.RentalsCardsList(data.rentals, 'RentalsPromoted', this.template),
      );
    } else {
      this.classList.add('d-none');
    }
  }
}

customElements.define('rentals-promoted', RentalsPromoted);
