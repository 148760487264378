/* eslint no-param-reassign:
  ["error", { "props": true, "ignorePropertyModificationsFor": ["element", "option"] }]
*/
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint no-underscore-dangle: ["error", { "allow": ["_config"] }] */
/* global smily, bootstrap, BookingSyncCalendarWidget, RangeSlider */

class RentalsFormResults extends HTMLElement {}

customElements.define('rentals-form-results', RentalsFormResults);

class RentalsFormPagination extends HTMLElement {
  paginate(pagination, filters) {
    const { total_count, total_pages, page } = pagination;
    const { t } = smily.website;
    const { rentalsCount } = t.rentalsForm.pagination;

    if (!total_count) {
      this.innerHTML = '';
      return;
    }

    // reset content
    this.innerHTML = `
      <b class="d-block mb-3">
        ${smily.t(rentalsCount[total_count < 2 ? 'one' : 'other'], { count: total_count })}
      </b>
    `;

    const isFirstPage = page === 1;
    const isLastPage = page === total_pages;

    const url = new URL(window.location);
    url.search = new URLSearchParams(filters);

    const pageUrl = (index) => {
      url.searchParams.set('page', index);
      return url;
    };

    // create list
    const ul = document.createElement('ul');
    ul.setAttribute('class', 'pagination justify-content-center mb-0');

    // create previous button
    const previous = document.createElement('li');
    previous.setAttribute('class', 'page-item');

    if (isFirstPage) previous.classList.add('disabled');

    previous.innerHTML = `
      <a class="page-link rounded prev" href="${isFirstPage ? '#' : pageUrl(page - 1)}" title="${t.rentalsForm.pagination.previous}">
        <span class="icon-group py-1">${smily.svgIcon('leftArrowAlt')}</span>
      </a>
    `;

    ul.appendChild(previous);

    // create list items

    const pageOffset = Math.max(page - 3, 0);
    const itemsLimit = 5;

    if (pageOffset > 0) {
      ul.insertAdjacentHTML('beforeend', `<li class="page-item"><a class="page-link rounded" href="${pageUrl(1)}">1</a></li>`);

      if (pageOffset !== 1) {
        ul.insertAdjacentHTML('beforeend', '<li class="page-item disabled"><a class="page-link rounded" href="#">…</a></li>');
      }
    }

    for (let i = 1 + pageOffset; i <= total_pages && i <= itemsLimit + pageOffset; i += 1) {
      const li = document.createElement('li');
      li.classList.add('page-item');

      if (i === page) li.classList.add('active');

      const a = document.createElement('a');
      a.setAttribute('class', 'page-link rounded');
      a.textContent = i;
      a.href = pageUrl(i);

      li.appendChild(a);
      ul.appendChild(li);
    }

    if (pageOffset + itemsLimit < total_pages) {
      if (pageOffset + itemsLimit + 1 !== total_pages) {
        ul.insertAdjacentHTML('beforeend', '<li class="page-item disabled"><a class="page-link rounded" href="#">…</a></li>');
      }

      ul.insertAdjacentHTML('beforeend', `<li class="page-item"><a class="page-link rounded" href="${pageUrl(total_pages)}">${total_pages}</a></li>`);
    }

    // create next button
    const next = document.createElement('li');
    next.setAttribute('class', 'page-item');

    if (isLastPage) next.classList.add('disabled');

    next.innerHTML = `
      <a class="page-link rounded prev" href="${isLastPage ? '#' : pageUrl(page + 1)}" title="${t.rentalsForm.pagination.next}">
        <span class="icon-group py-1">${smily.svgIcon('rightArrowAlt')}</span>
      </a>
    `;

    ul.appendChild(next);

    this.appendChild(ul);
  }
}

customElements.define('rentals-form-pagination', RentalsFormPagination);

class RentalsForm extends HTMLElement {
  constructor() {
    super();

    this.updateEvent = new CustomEvent('update');

    if (!this.rentalsFormResult) return;

    // store initial inner html
    this.initialInnerHTML = this.innerHTML;

    // store current state
    window.history.replaceState({}, '', new URL(window.location).toString());

    // restore state on browser action
    window.addEventListener('popstate', () => {
      // avoid conflicts with smily modal popstate
      if (document.location.hash) return;

      this.innerHTML = this.initialInnerHTML;
      this.getResults(this.getSearchParams(), true);
      this.initSubmit();
      // this.dispatchEvent(this.updateEvent);
    });

    // First load
    document.addEventListener('DOMContentLoaded', () => {
      this.getResults(this.getSearchParams(), true);
    });
  }

  get formInputs() {
    return this.querySelectorAll('rentals-form-input');
  }

  get options() {
    return [...this.formInputs].map((input) => input.options);
  }

  get rentalsFormResult() {
    return document.querySelector('rentals-form-results');
  }

  get rentalsSearchHero() {
    return document.querySelector('[data-rentals-form-hero]');
  }

  connectedCallback() {
    this.initSubmit();
    this.initLinks();
  }

  /**
   * @param {boolean} selected
   * @returns {Object}
   * @description Get values from options
  */
  valuesFromOptions(selected = false) {
    let values = {};
    const dataParams = this.getAttribute('data-params');

    if (dataParams) {
      const params = JSON.parse(dataParams);
      values = params;
    }

    this.formInputs.forEach((input) => {
      const options = selected ? input.optionsSelected : input.options;

      if (options) {
        options.forEach((option) => {
          const { name } = option;

          if (!name) return;

          if (!values[name]) values[name] = [];

          values[name].push(option.value);
        });
      }
    });

    return values;
  }

  /**
   * @returns {Array}
   * @description Get options names as an Array
  */
  getOptionsNames() {
    const names = [];

    this.formInputs.forEach((input) => {
      const { options } = input;

      if (options) {
        options.forEach((option) => {
          const { name } = option;

          if (!name) return;

          if (!names.includes(name)) names.push(name);
        });
      }
    });

    return names;
  }

  /**
   * @param {string} kindName
   * @returns {Object}
   * @description Get selected options names as an Object
   */
  selectedKindNames(kindName) {
    const names = [];

    this.formInputs.forEach((input) => {
      const { options } = input;

      if (options) {
        options.forEach((option) => {
          const { name, kind, selected } = option;

          if (!name || kind !== kindName || !selected) return;

          if (!names.includes(name)) names.push(name);
        });
      }
    });

    return names;
  }

  initSubmit() {
    this.querySelectorAll('[type=submit]').forEach((input) => {
      input.addEventListener('click', () => {
        this.submit();
      });
    });
  }

  initLinks() {
    if (!this.rentalsFormResult) return;

    const links = document.querySelectorAll('[data-rentals-form-links] a:not(.direct)');
    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const params = this.getSearchParams(event.currentTarget.href);
        this.getResults(params);
      });
    });
  }

  submit(query = {}) {
    this.dispatchEvent(new CustomEvent('submit'));

    if (this.rentalsFormResult) {
      const params = { ...this.getSearchParams(), ...query };

      // filter params to avoid conflicts with options and keep only
      // params that are not in options
      const optionsNames = this.getOptionsNames();
      const filteredData = Object.keys(params)
        .filter((key) => !optionsNames.includes(key))
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = params[key];
          return obj;
        }, {});

      if (filteredData.page) delete filteredData.page;

      this.getResults(filteredData);
    } else {
      // open search page
      const url = new URL(smily.website.searchUrl, window.location.origin);
      const searchParams = Object.assign(query, this.valuesFromOptions(true));
      url.search = new URLSearchParams(smily.paramsToRailsFormat(searchParams));

      if (smily.isDebug) url.searchParams.set('debug', smily.isDebug);
      if (smily.variant) url.searchParams.set('variant', smily.variant);

      window.location = url.toString();
    }
  }

  getTrackedParams() {
    const searchFilters = this.valuesFromOptions(true);
    const {
      checkin, checkout, adults, children, min_price, max_price,
    } = searchFilters;

    const params = new URL(document.location.href).searchParams;

    return {
      // params: new URLSearchParams(params).toString(),
      destination_ids: searchFilters['destination[]'],
      amenities: this.selectedKindNames('amenity'),
      rentals_types: searchFilters['rental_type[]'],
      booking_options: this.selectedKindNames('booking_option'),
      checkin: checkin?.[0],
      checkout: checkout?.[0],
      adults: adults?.[0],
      children: children?.[0],
      min_price: min_price?.[0],
      max_price: max_price?.[0],
      sort_by: searchFilters.sort_by?.[0],
      page: params.get('page') || '1',
      url: document.location.href,
    };
  }

  trackQueries() {
    // Check destinations and amenities arrays
    try {
      smily.analytics.tracker.trackSearchSubmit(this.getTrackedParams());
    } catch (error) {
      // silence
      console.log(error);
    }
  }

  trackDisplayRentals(rentals) {
    if (!rentals || !rentals.length) return;

    try {
      const rentalIds = rentals.map((rental) => rental.id);
      smily.analytics.tracker.trackDisplayRentals(rentalIds, this.getTrackedParams());
    } catch (error) {
      // silence
    }
  }

  async loadPage(filters, current, disablePushHistory) {
    // add cards placeholders
    this.rentalsFormResult.insertAdjacentHTML('beforeend', smily.RentalsCardsPlacholders());

    let sameDestinations;

    // request after first page load
    if (!disablePushHistory) {
      // get destination ids from the original search
      if (this.dataset.selectedDestinations) {
        const destinationIds = JSON.parse(this.dataset.selectedDestinations);
        const newDestinationIds = filters['destination[]'];

        sameDestinations = destinationIds.sort().toString() === newDestinationIds.sort().toString();
      }

      if (this.rentalsSearchHero && !sameDestinations) {
        // add css transition to animate height
        this.rentalsSearchHero.style.transition = 'height 0.3s ease-in-out';
        // animate height to 0 and hide
        this.rentalsSearchHero.style.height = `${this.rentalsSearchHero.offsetHeight}px`;
        setTimeout(() => {
          this.rentalsSearchHero.style.height = '0px';
        }, 0);
        // wait for animation to finish
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 300));
        // hide elements
        this.rentalsSearchHero.classList.add('d-none');
      }

      // scroll to top
      const { offsetTop } = document.querySelector('[role="main"]');
      const additionalOffset = this.rentalsSearchHero?.offsetHeight || 0;

      window.scrollTo(0, window.scrollY === 0 ? 0 : offsetTop + additionalOffset);
    }

    const { pathname } = new URL(document.location);

    let rentalsListUrl;

    const isCampaignPage = pathname.includes('/campaigns/');

    if (isCampaignPage) {
      // get campaign slug
      const [campaignSlug] = pathname.split('/campaigns/')[1].split('/');
      rentalsListUrl = smily.website.rentalsCampaignsListPath.replace('#', campaignSlug);
    }

    // fetch data
    const params = { ...filters, page: current };
    const data = await smily.rentalsList(params, rentalsListUrl);

    // remove cards placeholders
    this.rentalsFormResult.querySelectorAll('.card-placeholder').forEach((e) => e.remove());

    // insert new cards
    this.rentalsFormResult.insertAdjacentHTML('beforeend', smily.RentalsCardsList(data.rentals, 'rentalsForm'));

    // insert pagination
    const { pagination } = data.meta;
    document.querySelector('rentals-form-pagination')?.paginate(pagination, filters);

    // store new state
    if (!disablePushHistory) {
      const redirectToSearchUrl = !pathname.includes('/rentals/search/') && !sameDestinations;

      // update url
      const url = new URL(
        redirectToSearchUrl ? smily.website.searchPath : smily.website.searchUrl,
        window.location.origin,
      );

      url.search = new URLSearchParams(smily.paramsToRailsFormat(filters));

      if (smily.isDebug) url.searchParams.set('debug', smily.isDebug);
      if (smily.variant) url.searchParams.set('variant', smily.variant);

      window.history.pushState({}, '', url.toString());

      if (redirectToSearchUrl) {
        // set default search page title
        const title = document.querySelector('meta[name="rentals-search-page-title"]')?.content;
        if (title) document.title = title;
      }
    }

    // FIXME: this is a hack to fix the issue with the destination id missing on first load
    this.trackQueries();
    this.trackDisplayRentals(data.rentals);
    this.initLinks();
    this.dispatchEvent(this.updateEvent);
  }

  // convert query to object
  getResults(query = {}, disablePushHistory = false, clearResults = true) {
    // set clear results to false for infinite scroll pagination
    if (clearResults) this.rentalsFormResult.innerHTML = '';

    const valuesFromOptions = this.valuesFromOptions(true);
    const filters = { ...query, ...valuesFromOptions };
    const page = Number(filters.page || 1);

    // If on Categories and Destinations page we dont have destination input
    if (!valuesFromOptions['destination[]'] && this.dataset.selectedDestinations) {
      filters['destination[]'] = JSON.parse(this.dataset.selectedDestinations);
    }

    this.loadPage(filters, page, disablePushHistory);

    const { controller } = smily.website.currentPage;
    smily.trackers.ga('event', controller === 'search' ? 'generic' : controller, { event_category: 'search' });
    smily.trackers.fbq('track', 'Search', filters);
  }

  getSearchParams(url = document.location) {
    const { searchParams } = new URL(url);
    return Object.fromEntries([...searchParams]);
  }
}

customElements.define('rentals-form', RentalsForm);

class RentalsFormOption extends HTMLElement {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  get value() {
    return this.getAttribute('value');
  }

  set value(value) {
    if (value) {
      this.setAttribute('value', value);
    } else {
      this.removeAttribute('value');
    }
  }

  get name() {
    return this.getAttribute('name');
  }

  get min() {
    return this.getAttribute('min');
  }

  get max() {
    return this.getAttribute('max');
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(value) {
    if (value) {
      this.setAttribute('selected', value);
    } else {
      this.removeAttribute('selected');
    }
  }

  get kind() {
    return this.getAttribute('kind');
  }

  connectedCallback() {
    const params = new URLSearchParams(document.location.search);
    const paramValue = params.get(this.name);

    if (paramValue && !this.value) {
      this.value = paramValue;
      this.selected = !!paramValue;
    }

    this.classList.toggle('active', this.selected);
  }

  static get observedAttributes() {
    return ['selected', 'value'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.classList.toggle('active', newValue);
      this.dispatchEvent(new Event(name === 'selected' ? 'selected' : 'valueChanged'));
    }
  }
}

customElements.define('rentals-form-option', RentalsFormOption);

class RentalsFormInput extends HTMLElement {
  constructor() {
    super();

    this.formatLabel = (value) => value;

    this.form = this.closest('rentals-form');
  }

  get type() {
    return this.getAttribute('type');
  }

  get name() {
    return this.getAttribute('name');
  }

  get placeHolder() {
    return this.getAttribute('placeholder');
  }

  get value() {
    return this.getAttribute('value');
  }

  set value(value) {
    if (value) {
      this.setAttribute('value', value);
    } else {
      this.removeAttribute('value');
    }
  }

  get format() {
    return this.getAttribute('format');
  }

  get options() {
    return this.querySelectorAll('rentals-form-option');
  }

  get optionsSelected() {
    const options = Array.from(this.options);
    return options.filter((option) => option.selected);
  }

  get btnClass() {
    return this.getAttribute('btn-class');
  }

  connectedCallback() {
    if (this.ready === true) {
      return;
    }

    this.watchOptionsEnabled = !this.hasAttribute('data-disable-watch-options');
    this.resetOptionsCallback = () => undefined;

    switch (this.type) {
      case 'destinations-remote':
        this.initFormInput();
        this.initDestinationsRemote();
        break;
      case 'dates':
        this.initFormInput();
        this.initCalendar();
        break;
      case 'price-range':
        this.initFormInput();
        this.initPriceRange();
        break;
      case 'checkboxes':
        this.initFormInput();
        this.initCheckboxes();
        break;
      case 'select':
        this.initFormInput();
        this.initSelect();
        break;
      case 'select-multiple':
        this.initFormInput();
        this.initSelect(true);
        break;
      case 'increment':
        this.initFormInput();
        this.initIncrement();
        break;
      case 'boolean':
        this.initBoolean();
        break;
      default:
        break;
    }

    // Remove placeholder class
    this.removeAttribute('class');
    this.removeAttribute('btn-class');

    this.ready = true;
  }

  static get observedAttributes() {
    // triggers attributeChangedCallback when changed
    return ['value'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.active = !!newValue;

      if (this.label) {
        const formattedValue = this.formatLabel(this.value);
        this.label.textContent = newValue ? formattedValue : this.placeHolder;
        this.label.classList.toggle('active', newValue);
      }

      this.form.dispatchEvent(new Event('change'));
    }
  }

  labelFromSelected() {
    const values = this.optionsSelected;
    return values.map((option) => option.textContent);
  }

  watchOptions(updateLabel = true, fn = null, eventName = 'selected') {
    this.options.forEach((option) => {
      option.addEventListener(eventName === 'selected' ? 'selected' : 'valueChanged', () => {
        const event = new Event('change');
        this.dispatchEvent(event);

        if (updateLabel) this.value = this.labelFromSelected();
        if (fn) fn(option);
      });
    });
  }

  resetOptions(exceptValue = null) {
    this.options.forEach((element) => {
      if (element.value !== exceptValue) {
        element.selected = false;
      }
    });

    this.resetOptionsCallback();
  }

  toggleOptionOnClick(option, single = false) {
    option.addEventListener('click', (event) => {
      const { currentTarget } = event;
      const { selected } = currentTarget;
      if (single === true) {
        this.resetOptions(currentTarget.value);
      }
      currentTarget.selected = !selected;
    });
  }

  initFormInput() {
    this.isDropdown = this.hasAttribute('data-dropdown');

    if (this.isDropdown) {
      this.initInputDropdown();
    } else {
      this.initInputInline();
    }
  }

  initInputInline() {
    const inputTemplate = `
      <div class="rentals-form-input-inline">
        <h2 class="mb-3" data-label>${this.placeHolder}</h2>
        <div data-content></div>
      </div>
    `;

    this.insertAdjacentHTML('beforeend', inputTemplate);

    this.content = this.querySelector('[data-content]');
  }

  initInputDropdown() {
    const dropdownTemplate = smily.templates.dropdown({
      btnClass: this.btnClass,
      label: this.placeHolder,
      clear: smily.website.t.clear,
      next: smily.website.t.next,
      submit: smily.website.t.submit,
      validate: smily.website.t.validate,
    });

    this.insertAdjacentHTML('beforeend', dropdownTemplate);

    // Assign dropdown elements
    this.dropdownToggle = this.querySelector('[data-bs-toggle="dropdown"]');
    this.dropdownHeader = this.querySelector('.dropdown-header');
    this.dropdownContent = this.querySelector('.dropdown-content');
    this.dropdownClear = this.querySelector('[type="reset"]');
    this.dropdownBack = this.querySelector('[type="button"][name="back"]');
    this.dropdownNext = this.querySelector('[type="button"][name="next"]');
    this.dropdownSubmit = this.querySelector('[type="submit"]');
    this.dropdownValidate = this.querySelector('[type="button"][name="validate"]');

    this.label = this.dropdownToggle;
    this.content = this.dropdownContent;

    // Avoid user select on inputs
    this.dropdownContent.classList.add('user-select-none');

    // Move options to dropdown content
    this.options.forEach((option) => {
      this.dropdownContent.appendChild(option);
    });

    // Toggle Submit and Validate button
    this.dropdownSubmit.classList.toggle('d-none', this.hasAttribute('data-enable-validate'));
    this.dropdownValidate.classList.toggle('d-none', !this.hasAttribute('data-enable-validate'));

    // Init Bootstrap Dropdown
    const popperConfig = {
      display: 'static',
      autoClose: 'outside',
    };

    this.dropdown = new bootstrap.Dropdown(this.dropdownToggle, popperConfig);

    const paginationEnabled = this.hasAttribute('data-enable-pagination');
    const navigationEnabled = this.hasAttribute('data-enable-navigation');

    // Back & Next button for mobile
    if (paginationEnabled || navigationEnabled) {
      const { formInputs } = this.form;
      const dropdownInputs = [...formInputs].filter((input) => input.hasAttribute('data-dropdown'));
      const inputIndex = Array.prototype.indexOf.call(dropdownInputs, this);

      // Add pagination
      if (paginationEnabled) {
        this.dropdownHeader.querySelector('span').outerHTML = dropdownInputs.map((dropdown, index) => {
          const classes = dropdown === this ? 'active btn-dark' : 'btn-secondary';
          const placeHolder = dropdown.getAttribute('placeholder');
          return `<button type="button" class="${classes} btn" data-target="${index}">${placeHolder}</button>`;
        }).join('');

        this.dropdownHeader.querySelectorAll('[type="button"]').forEach((span) => {
          span.addEventListener('click', () => {
            this.dropdown.hide();
            setTimeout(() => dropdownInputs[span.dataset.target].dropdown.show());
          });
        });
      }

      const openPreviousDropdown = () => {
        this.dropdown.hide();
        if (inputIndex !== 0) {
          setTimeout(() => formInputs[inputIndex - 1].dropdown.show());
        }
      };

      const openNextDropdown = () => {
        this.dropdown.hide();
        if (inputIndex !== dropdownInputs.length - 1) {
          setTimeout(() => dropdownInputs[inputIndex + 1].dropdown.show());
        } else {
          this.form.submit();
        }
      };

      // Add back & next buttons
      if (navigationEnabled) {
        // Back
        this.dropdownBack.classList.remove('invisible');
        this.dropdownBack.addEventListener('click', openPreviousDropdown);

        // Next
        if (inputIndex !== dropdownInputs.length - 1) {
          this.dropdownNext.classList.remove('invisible');
          this.dropdownNext.addEventListener('click', openNextDropdown);
        }
      } else {
        this.dropdownBack.classList.add('d-none');
        this.dropdownNext.classList.add('d-none');
      }

      // Add swipe events
      this.addEventListener('swiped-left', openNextDropdown);
      this.addEventListener('swiped-right', openPreviousDropdown);
    }

    // rentals-form has position-sticky so we need to add a backdrop inside the form element
    // otherwise it generates zIndex issues with the dropdown
    this.form.modalBackdrop = this.form.querySelector('.modal-backdrop');

    if (!this.form.modalBackdrop) {
      this.form.modalBackdrop = document.createElement('div');
      this.form.modalBackdrop.setAttribute('class', 'modal-backdrop fade d-lg-none pe-none');
      this.form.prepend(this.form.modalBackdrop);
    }

    this.dropdownToggle.addEventListener('show.bs.dropdown', () => {
      if (smily.mediaBreakPoint.down('lg')) {
        this.form.modalBackdrop.classList.remove('pe-none');
        setTimeout(() => this.form.modalBackdrop.classList.add('show'));
        document.body.classList.add('overflow-hidden');
      }
    });

    this.dropdownToggle.addEventListener('hidden.bs.dropdown', () => {
      if (smily.mediaBreakPoint.down('lg')) {
        this.form.modalBackdrop.classList.remove('show');
        this.form.modalBackdrop.classList.add('pe-none');
        document.body.classList.remove('overflow-hidden');
      }
    });

    // Bind click on clear
    this.dropdownClear.addEventListener('click', () => {
      this.resetOptions();
    });

    this.dropdownSubmit.addEventListener('click', () => {
      this.form.submit();
      this.dropdown.hide();
    });

    this.dropdownValidate.addEventListener('click', () => {
      this.dropdown.hide();
    });
  }

  initDestinationsRemote() {
    this.formatLabel = (string) => {
      const { length } = this.optionsSelected;
      return length > 1 ? smily.t(this.format, { count: length }) : string;
    };

    this.dropdownContent.parentElement.classList.add('pt-2');
    this.dropdownContent.insertAdjacentHTML('afterbegin', smily.templates.destinationDropdown);
    this.dropdownContent.insertAdjacentHTML('beforeend', `
      <div data-content="selected" class="d-none"></div>
      <div data-content="results"></div>
      <div data-content="destinations"></div>
    `);

    const input = this.dropdownContent.querySelector('[type="search"]');
    const clear = this.dropdownContent.querySelector('[type="button"]');
    const noresult = this.dropdownContent.querySelector('[data-no-result]');
    const resultsEl = this.dropdownContent.querySelector('[data-content="results"]');
    const destinationsEl = this.dropdownContent.querySelector('[data-content="destinations"]');
    const selectedEl = this.dropdownContent.querySelector('[data-content="selected"]');

    this.dropdownContent.style.minWidth = '33ch';

    this.dropdownToggle.addEventListener('click', () => {
      setTimeout(() => input.focus(), 100);
    });

    const addDestinations = (destinations, element) => {
      destinations.forEach(({ title, id }) => {
        const item = document.createElement('div');
        item.classList.add('filter-options-toggle-wrapper');
        item.innerHTML = `<button value="${id}" role="button">${title}</button>`;
        element.appendChild(item);

        item.addEventListener('click', () => {
          // reset selected because we are in single mode
          this.resetOptions();
          selectedEl.innerHTML = `<rentals-form-option value="${id}" name="destination[]" role="button">${title}</rentals-form-option>`;

          if (this.watchOptionsEnabled) this.watchOptions();
          selectedEl.querySelector(`[value="${id}"]`).selected = true;

          // go to next dropdown
          this.dropdown.hide();
          const { formInputs } = this.form;
          const dropdownInputs = [...formInputs].filter((i) => i.hasAttribute('data-dropdown'));
          const inputIndex = Array.prototype.indexOf.call(dropdownInputs, this);
          setTimeout(() => dropdownInputs[inputIndex + 1].dropdown.show());

          // hide results and show destinations
          input.value = '';
          destinationsEl.classList.remove('d-none');
          resultsEl.classList.add('d-none');
        });
      });
    };

    const searchDestinations = () => {
      const { value } = input;
      const val = value.toLowerCase();
      clear.classList.toggle('d-none', !value);

      if (val === '') {
        // cancel any existing fetch request
        if (searchDestinations.controller) {
          searchDestinations.controller.abort();
        }

        destinationsEl.classList.remove('d-none');
        resultsEl.classList.add('d-none');
        noresult.classList.add('d-none');
        return;
      }

      // Cancel any existing fetch request
      if (searchDestinations.controller) {
        searchDestinations.controller.abort();
      }

      // Create a new AbortController for the fetch request
      searchDestinations.controller = new AbortController();

      fetch(`/api/public/v2/destinations?search_phrase=${val}`, { signal: searchDestinations.controller.signal })
        .then((response) => response.json())
        .then((data) => {
          // Reset controller after successful fetch
          searchDestinations.controller = null;

          const { data: destinations } = data;

          noresult.classList.toggle('d-none', destinations.length);

          if (!destinations.length) {
            destinationsEl.classList.remove('d-none');
            resultsEl.classList.add('d-none');
            return;
          }

          resultsEl.innerHTML = '';
          addDestinations(destinations, resultsEl);

          if (this.watchOptionsEnabled) this.watchOptions();

          destinationsEl.classList.add('d-none');
          resultsEl.classList.remove('d-none');
        })
        .catch(() => {
          // Reset controller after fetch error
          searchDestinations.controller = null;
        });
    };

    // Initialize controller variable
    searchDestinations.controller = null;

    input.addEventListener('input', searchDestinations);

    input.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.dropdownToggle.click();
      }
    });

    clear.addEventListener('click', () => {
      input.value = '';
      clear.classList.add('d-none');
      noresult.classList.add('d-none');
      input.focus();
      searchDestinations();
    });

    const selectedDestinations = JSON.parse(this.form.getAttribute('data-selected-destinations'));

    // Set label on init
    if (selectedDestinations?.length) {
      const selectedDestinationsIDs = selectedDestinations.map((id) => `ids[]=${id}`).join('&');

      fetch(`/api/public/v2/destinations?${selectedDestinationsIDs}`)
        .then((response) => response.json())
        .then((data) => {
          const { data: destinations } = data;
          const titles = destinations.map((destination) => destination.title);
          const { length } = titles;
          const format = smily.t(this.format, { count: length });
          const labels = titles.length > 1 ? format : titles[0];

          this.value = labels;
        });
    }

    // Fetch destinations
    fetch('/api/public/v2/destinations/promoted')
      .then((response) => response.json())
      .then((body) => {
        if (body?.data?.length === 0) {
          return fetch('/api/public/v2/destinations/roots')
            .then((response) => response.json());
        }

        return body;
      })
      .then((body) => {
        const { data: destinations } = body;

        destinationsEl.insertAdjacentHTML('beforeend', `<div class="filter-options-header"><h6>${smily.website.t.rentalsForm.destinations.suggestions.title}</h6></div>`);
        addDestinations(destinations, destinationsEl);

        if (this.watchOptionsEnabled) this.watchOptions();
      });

    // Assign callback on resetOptions
    this.resetOptionsCallback = () => {
      this.value = this.storedValue || '';
    };
  }

  initCalendar() {
    const [optionStart, optionEnd] = this.options;
    const calendar = document.createElement('div');
    const { rentalId } = this.dataset;

    this.dropdownContent.appendChild(calendar);

    const event = new Event('change');

    // Init Calendar
    this.calendar = new BookingSyncCalendarWidget({
      apiHost: smily.website.apiHost,
      theme: 'basic',
      el: calendar,
      rentalId,
      elStartAt: optionStart,
      elEndAt: optionEnd,
      selectable: true,
      lang: smily.website.lang,
      formatDate: '%Y-%m-%d',
      displayMonths: smily.mediaBreakPoint.up('sm') ? 2 : 12,
      minStay: parseInt(this.getAttribute('data-min-stay')) || 0,
      onSelectionCompleted: (start, end) => {
        optionStart.selected = !!start;
        optionEnd.selected = !!end;

        if (this.watchOptionsEnabled) {
          this.value = smily.formatDates(start, end);
          this.dispatchEvent(event);
        }
      },
      onClearSelection: () => {
        if (this.watchOptionsEnabled) {
          this.value = null;
          this.dispatchEvent(event);
        }
      },
    });

    this.calendar.on('selection-reset', () => {
      optionStart.value = null;
      optionEnd.value = null;
      optionStart.selected = false;
      optionEnd.selected = false;
    });

    // Open dropdown if no dates are selected
    if (!optionStart.value && !optionEnd.value && this.form.getAttribute('data-display') === 'lg' && smily.mediaBreakPoint.up('md')) {
      this.dropdown.show();
    }

    // Set label on init
    if (optionStart.value && optionEnd.value) {
      this.value = smily.formatDates(optionStart.value, optionEnd.value);
    }

    // Bind click on clear
    if (this.isDropdown) {
      this.dropdownClear.addEventListener('click', () => {
        this.calendar.resetSelection();
      });
    }
  }

  initCheckboxes() {
    this.formatLabel = () => {
      const { length } = this.optionsSelected;
      const formats = this.format.split('|');

      return smily.t(formats[length > 1 ? 1 : 0], { count: length });
    };

    if (!this.isDropdown) {
      this.content.setAttribute('class', 'mx-0 row row-cols-md-2');
    }

    const optionsGroups = this.getAttribute('group-names');

    if (optionsGroups) {
      this.groupNames = this.getAttribute('group-names').split('|');

      this.groupNames.forEach((groupName) => {
        const group = document.createElement('div');
        group.setAttribute('class', 'py-2 px-0 w-100');

        const groupTitle = document.createElement('div');
        groupTitle.innerHTML = `<h6 class="mb-2">${smily.website.t.rentalsForm.amenities.groupTitles[groupName]}</h6>`;
        group.appendChild(groupTitle);

        const groupContent = document.createElement('div');
        groupContent.setAttribute('class', 'mx-0 row row-cols-md-2');
        groupContent.setAttribute('group-name', groupName);
        group.appendChild(groupContent);

        this.content.appendChild(group);
      });
    }

    this.options.forEach((option) => {
      const wrapper = document.createElement('div');

      if (option.hasAttribute('hidden')) {
        wrapper.setAttribute('class', 'd-none');
      } else {
        wrapper.setAttribute('class', this.isDropdown ? 'py-1 px-3' : 'py-2 px-0');
      }

      wrapper.setAttribute('role', 'button');
      wrapper.innerHTML = '<input class="form-check-input me-1" type="checkbox">';
      wrapper.appendChild(option);

      // checkbox is just an eyecandy, should not be used for its value
      const checkbox = wrapper.querySelector('[type="checkbox"]');
      checkbox.checked = option.selected;
      this.content.appendChild(wrapper);

      // Bind click on option (toggles selected)
      wrapper.addEventListener('click', () => {
        option.selected = !option.selected;
        checkbox.checked = option.selected;
      });

      if (this.groupNames) {
        const groupName = this.groupNames[Number(option.getAttribute('group-id'))];
        const group = this.content.querySelector(`[group-name="${groupName}"]`);
        group.appendChild(wrapper);
      }
    });

    // Hide empty groups
    if (this.groupNames) {
      this.groupNames.forEach((groupName) => {
        const group = this.content.querySelector(`[group-name="${groupName}"]`);
        group.parentElement.classList.toggle('d-none', !group.querySelector('rentals-form-option'));
      });
    }

    // Set label on init
    this.value = this.labelFromSelected();

    if (this.watchOptionsEnabled) {
      this.watchOptions();
    }

    this.resetOptions = () => {
      this.options.forEach((option) => {
        option.selected = false;
        option.parentElement.querySelector('[type="checkbox"]').checked = false;
      });
    };
  }

  initSelect(multiple = false) {
    if (multiple) {
      this.formatLabel = (string) => {
        const { length } = this.optionsSelected;
        let formatted = string;

        if (length > 1) {
          formatted = smily.t(this.format, { count: length });
        }

        return formatted;
      };
    }

    if (!this.isDropdown) {
      const isWrap = this.hasAttribute('data-enable-select-wrap');
      const wrap = isWrap ? 'flex-wrap' : 'flex-nowrap';
      if (!isWrap) this.content.setAttribute('data-swipe-ignore', '');
      this.content.setAttribute('class', `d-flex gap-2 py-2 ${wrap}`);
    }

    this.options.forEach((option) => {
      if (this.isDropdown) {
        option.classList.add('dropdown-item');
      } else {
        this.content.appendChild(option);
        option.setAttribute('class', 'btn btn-outline-dark rounded-pill');
        if (option.selected) option.classList.add('active');
      }

      // Bind click on option (toggles selected)
      this.toggleOptionOnClick(option, !multiple);
    });

    if (!multiple) {
      // Make sure only one option is selected on init
      this.resetOptions(this.optionsSelected[0]?.value);
    }

    // Set label on init
    this.value = this.labelFromSelected();

    if (this.watchOptionsEnabled) {
      this.watchOptions();
    }
  }

  initPriceRange() {
    this.formatLabel = (string) => {
      const {
        min, max, minValue, maxValue,
      } = this.slider;
      const formats = this.format.split('|');
      let formatted = string;

      if (min !== minValue && max !== maxValue) {
        formatted = formats[1]; // eslint-disable-line prefer-destructuring
      } else if (max !== maxValue) {
        formatted = formats[2]; // eslint-disable-line prefer-destructuring
      } else {
        formatted = formats[0]; // eslint-disable-line prefer-destructuring
      }

      formatted = smily.t(formatted, {
        min: smily.formatPrice(minValue),
        max: smily.formatPrice(maxValue),
      });

      return formatted;
    };

    const optionMin = this.options[0];
    const optionMax = this.options[1];
    const sliderWrapper = document.createElement('div');
    this.content.appendChild(sliderWrapper);
    sliderWrapper.setAttribute('class', 'mx-3');

    // Init slider
    this.slider = new RangeSlider({
      element: sliderWrapper,
      min: optionMin.min,
      max: optionMax.max,
      minValue: optionMin.value,
      maxValue: optionMax.value,
      step: 1,
      beyondMax: true,
    });

    // Set label on init
    if (this.slider.min !== this.slider.minValue || this.slider.max !== this.slider.maxValue) {
      this.value = [this.slider.minValue, this.slider.maxValue];
    } else {
      this.value = [];
    }

    const setValue = (minValue, maxValue) => {
      const { min, max } = this.slider;
      if (min !== minValue || max !== maxValue) {
        this.value = [minValue, maxValue];
      } else {
        this.value = [];
      }
    };

    // Set label on move
    if (this.watchOptionsEnabled) this.slider.onMoveCb = setValue;

    let disableAutoclose;

    if (this.isDropdown) {
      this.slider.onStartCb = () => {
        clearTimeout(disableAutoclose);
        this.dropdown._config.autoClose = false;
      };
    }

    this.slider.onStopCb = (minValue, maxValue) => {
      const { min, max } = this.slider;
      // Fill options
      optionMin.value = minValue;
      optionMin.selected = minValue !== min;
      optionMax.value = maxValue;
      optionMax.selected = maxValue !== max;

      // Set label
      if (this.watchOptionsEnabled) setValue(minValue, maxValue);

      if (this.isDropdown) {
        const { autoClose } = this.dropdown._config;
        disableAutoclose = setTimeout(() => {
          this.dropdown._config.autoClose = autoClose;
        }, 50);
      }
    };

    const datesInput = this.form.querySelector('rentals-form-input[type="dates"]');

    if (!datesInput) {
      console.log('RentalsFormInput - initPriceRange - no dates input found');
    } else {
      const updateValues = () => {
        const [checkin, checkout] = datesInput.optionsSelected.map((o) => o.value);
        const { slider } = this;

        if (!this.initialValues) {
          const {
            min, minValue, max, maxValue,
          } = slider;

          this.initialValues = {
            min, minValue, max, maxValue,
          };
        }

        // Length of stay
        const los = smily.getNumNights(checkin, checkout);
        const values = this.initialValues;

        if (!checkin && !checkout) {
          slider.min = values.min;
          slider.max = values.max;

          slider.setMinValue(values.minValue);
          slider.setMaxValue(values.maxValue);
          return;
        }

        slider.min = values.min * los;
        slider.max = values.max * los;

        slider.setMinValue(values.minValue * los);
        slider.setMaxValue(values.maxValue * los);

        slider.initChart();
        slider.loadChartData();
      };

      // Update values on init
      updateValues();

      // Update values when dates are selected
      datesInput.addEventListener('change', updateValues);
    }

    // Update chart on new form results displayed
    this.form.addEventListener('update', () => {
      this.slider.initChart();
      this.slider.loadChartData();
    });

    this.resetOptions = () => {
      this.value = '';
      this.slider.reset();
      optionMin.selected = false;
      optionMax.selected = false;
    };
  }

  initIncrement() {
    const { options } = this;

    this.formatLabel = () => {
      let value = 0;
      options.forEach((option) => {
        value += parseInt(option.value || 0);
      });
      const formats = this.format.split('|');
      const formatted = smily.t(formats[value > 1 ? 1 : 0], { count: value });
      return formatted;
    };

    const sumOptionValues = () => [...options].map((opt) => Number(opt.value))
      .reduce((total, currentValue) => total + currentValue, 0);

    this.dropdownContent.setAttribute('class', 'd-grid dropdown-content gap-3 px-4 py-2 user-select-none');

    const maxCombined = parseInt(this.getAttribute('max-combined'));

    options.forEach((option) => {
      const label = option.textContent;
      const value = option.value || 0;
      const btnClass = 'btn btn-outline-dark btn-sm d-inline-flex p-0 rounded-3 rounded-circle';
      const template = `
        <div class="hstack gap-3">
          ${label}
          <button type="button" name="minus" class="${btnClass} ms-auto">${smily.svgIcon('minus', { class: 'fill-current-color m-2' })}</button>
          <span class="increment-value">${value}</span>
          <button type="button" name="plus" class="${btnClass}">${smily.svgIcon('plus', { class: 'fill-current-color m-2' })}</button>
        </div>
      `;

      this.dropdownContent.insertAdjacentHTML('beforeend', template);
      const container = this.dropdownContent.querySelector('div:last-of-type');
      const buttons = container.querySelectorAll('[name="minus"], [name="plus"]');
      const valueElement = container.querySelector('.increment-value');

      option.buttons = {};
      // eslint-disable-next-line prefer-destructuring
      option.buttons.minus = buttons[0];
      // eslint-disable-next-line prefer-destructuring
      option.buttons.plus = buttons[1];

      const setButtonsDisabled = () => {
        options.forEach((o) => {
          if (!o.buttons) return;
          const { minus, plus } = o.buttons;

          if (parseInt(o.value) <= parseInt(o.min) || o.value === '' || o.value === null) {
            minus.setAttribute('disabled', 'disabled');
          } else {
            minus.removeAttribute('disabled');
          }

          if (parseInt(o.value) >= parseInt(o.max)) {
            plus.setAttribute('disabled', 'disabled');
          } else {
            plus.removeAttribute('disabled');
          }

          if (!maxCombined) return;

          const values = [...options].map((opt) => parseInt(opt.value) || 0);
          const sum = values.reduce((acc, curr) => acc + curr);
          const combined = maxCombined && sum >= maxCombined;

          if (combined) plus.setAttribute('disabled', 'disabled');
        });
      };

      setButtonsDisabled();

      // Bind click on buttons
      buttons.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const val = parseInt(option.value) || 0;

          if (event.currentTarget.name === 'plus') {
            if (val < parseInt(option.max)) {
              option.value = val + 1;
            }
          } else if (val > parseInt(option.min)) {
            option.value = val - 1;
          }

          setButtonsDisabled();

          option.selected = !!option.value;
          valueElement.innerHTML = `<span>${option.value || 0}</span>`;
          this.value = sumOptionValues();
        });
      });
    });

    // Set max combined label
    if (maxCombined) {
      const t = smily.website.t.rentalsForm.guests.maxCombined;
      this.dropdownContent.insertAdjacentHTML('beforeend', `
        <div class="text-muted small">${smily.t(maxCombined <= 1 ? t[0] : t[1], { count: maxCombined })}</div>
      `);
    }

    // Set label on init
    this.value = sumOptionValues();

    if (this.watchOptionsEnabled) {
      this.watchOptions(true, null, 'valueChanged');
    }

    // Bind click on clear
    if (this.isDropdown) {
      this.dropdownClear.addEventListener('click', () => {
        options.forEach((option) => {
          option.value = 0;
          option.selected = false;
        });

        this.dropdownContent.querySelectorAll('.increment-value').forEach((element) => {
          element.textContent = 0;
        });
      });
    }
  }

  initBoolean() {
    const option = this.options[0];

    option.setAttribute('class', this.btnClass);
    option.setAttribute('type', 'submit');

    // Bind click on buttons
    this.toggleOptionOnClick(option);
    this.addEventListener('click', () => {
      this.form.submit();
    });
  }
}

customElements.define('rentals-form-input', RentalsFormInput);

document.addEventListener('DOMContentLoaded', () => {
  const filtersBtnLg = document.getElementById('rentalsFormfiltersBtnLg');

  if (filtersBtnLg) {
    const form = document.querySelector(filtersBtnLg.getAttribute('data-target')) || filtersBtnLg.closest('rentals-form');
    const formInputs = [...form.formInputs];

    const updateFiltersBtnState = () => {
      const inlineInputs = formInputs.filter((input) => !input.isDropdown || input.getAttribute('data-disable-badge'));
      const activesCount = inlineInputs.filter((input) => input.active).length;
      const badge = filtersBtnLg.querySelector('.badge');

      filtersBtnLg.classList.toggle('active', activesCount);
      badge.textContent = activesCount;
      badge.classList.toggle('d-none', !activesCount);
    };

    updateFiltersBtnState();

    form.addEventListener('change', () => {
      updateFiltersBtnState();
    });
  }

  const toggler = document.getElementById('rentalsFormToggler');

  if (toggler) {
    const form = document.querySelector(toggler.getAttribute('data-target')) || toggler.closest('rentals-form');
    const formInputs = [...form.formInputs];
    const content = toggler.querySelector('[data-content]');

    toggler.addEventListener('click', () => {
      // trick to wait for the backdrop to be added to the DOM
      setTimeout(() => {
        form.formInputs[0].dropdown.show();
      });
    });

    if (!content) {
      return;
    }

    const placeHolder = content.innerHTML;
    const labels = {
      destinations: '',
      dates: '',
      guests: '',
    };

    const { rentalsForm } = smily.website.t;
    const dropdownInputs = formInputs.filter((input) => input.isDropdown);
    const updateLabels = () => {
      dropdownInputs.forEach((input) => {
        if (input.type === 'destinations' || input.type === 'destinations-remote') {
          const value = input.value?.split(',');
          if (!value) return;
          const { length } = value;
          const label = length > 1 ? smily.t(input.format, { count: length }) : value[0];
          labels.destinations = label || rentalsForm.destinations.placeholderAlt;
        } else if (input.type === 'dates') {
          labels.dates = input.value || rentalsForm.dates.placeholderAlt;
        } else if (input.type === 'increment') {
          labels.guests = input.value?.concat('+') || rentalsForm.guests.placeholderAlt;
        }
      });

      if (dropdownInputs.filter((input) => input.active).length === 0) {
        content.innerHTML = placeHolder;
      } else {
        content.innerHTML = `
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <div class="vstack small">
                <span class="d-inline-block text-truncate fw-bold" style="max-width: 150px;">
                  ${labels.destinations.trim()}
                </span>
                <div class="d-flex gap-2 opacity-75">
                  <div class="d-inline-flex icon-group gap-1">
                    <span>${labels.dates.trim()}</span>
                  </div>
                  <div class="d-inline-flex icon-group gap-1">
                    ${smily.svgIcon('guests', { w: 16, h: 16 })}
                    <span>${labels.guests.trim()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
      }
    };

    updateLabels();

    const filtersBtn = document.getElementById('rentalsFormfiltersBtn');
    filtersBtn.classList.add('position-relative');
    filtersBtn.insertAdjacentHTML(
      'beforeend',
      `<div class="position-relative">
        <span data-badge class="bg-primary ms-1 p-1 position-absolute rounded-circle start-100 top-0 translate-middle">
        </span>
      </div>`,
    );

    const badge = filtersBtn.querySelector('[data-badge]');
    const inlineInputs = formInputs.filter((input) => !input.isDropdown || input.getAttribute('data-disable-badge'));
    const updateFiltersBadge = () => {
      if (inlineInputs.filter((input) => input.active).length === 0) {
        badge.classList.add('d-none');
      } else {
        badge.classList.remove('d-none');
      }
    };

    updateFiltersBadge();

    form.addEventListener('change', () => {
      updateLabels();
      updateFiltersBadge();
    });
  }

  const offcanvas = document.getElementById('offcanvasRentalsFormFilters');

  // Add swipe events and reset to offcanvas
  if (offcanvas) {
    const form = document.querySelector(offcanvas.getAttribute('data-target')) || offcanvas.closest('rentals-form');

    offcanvas.querySelector('.offcancas-footer [type="reset"]').addEventListener('click', () => {
      [...form.formInputs].filter((node) => !node.hasAttribute('data-dropdown')).forEach((input) => {
        input.resetOptions();
      });
    });

    offcanvas.addEventListener('swiped-right', (e) => {
      if (e.target.closest('[data-swipe-ignore]') || e.target.hasAttribute('data-swipe-ignore')) {
        return;
      }

      bootstrap.Offcanvas.getInstance(offcanvas).hide();
    });

    offcanvas.addEventListener('swiped-left', (e) => {
      if (e.target.closest('[data-swipe-ignore]') || e.target.hasAttribute('data-swipe-ignore')) {
        return;
      }

      bootstrap.Offcanvas.getInstance(offcanvas).hide();
      form.submit();
    });

    form.addEventListener('submit', () => {
      bootstrap.Offcanvas.getInstance(offcanvas)?.hide();
    });
  }
});
