/* eslint-disable no-console */
/* eslint-disable camelcase */
/* global smily, BookingSyncCalendarWidget */

class RentalBooknow extends HTMLElement {
  constructor() {
    super();

    window.addEventListener('DOMContentLoaded', () => this.init());

    this.initInquiryModal();
  }

  get formInputs() {
    return this.querySelectorAll('rentals-form-input');
  }

  get bookableInputs() {
    return this.querySelectorAll('[data-rental-booknow="fee-id"], [data-rental-booknow="fee-time-booked"]');
  }

  init() {
    this.form = this.querySelector('rentals-form');
    this.errorElement = this.querySelector('[role="alert"]');
    this.inputDates = this.querySelector('rentals-form-input[type="dates"]');
    this.btnBooknow = this.querySelector('[data-rental-booknow="btnBooknow"]');
    this.btnContact = this.querySelector('[data-rental-booknow="btnContact"]');
    this.priceContainer = this.querySelector('[data-rental-booknow="priceContainer"]');
    this.priceDetails = this.querySelector('[data-rental-booknow="priceDetails"]');
    this.discountCodeToggler = this.querySelector('[data-rental-booknow="discountCodeToggler"]');
    this.discountCodeBtn = this.querySelector('[data-rental-booknow="discountCodeBtn"]');
    this.discountCodeInput = this.querySelector('[data-rental-booknow="discountCodeInput"]');
    this.rentalId = this.dataset.rentalId;

    this.apiHost = window.smily.website.apiHost;

    // Options
    this.trackGoogleAnalyticsEvents = !this.hasAttribute('do-not-track-google-analytics-events');
    this.trackFacebookEvents = !this.hasAttribute('do-not-track-facebook-events');

    // Bind events
    this.initTrackers();

    this.inputDates.calendar.on('selection-completed', () => {
      this.inputDates.dropdown.hide();
    });

    // Remove change event on selection clear
    this.inputDates.calendar.opts.onClearSelection = () => {
      if (this.inputDates.watchOptionsEnabled) {
        this.value = null;
      }
    };

    this.onChangeHandler = smily.debounce(this.onChangeCb.bind(this), 350);

    // Listen to changes on rental-form inputs
    this.formInputs.forEach((input) => {
      input.addEventListener('change', this.onChangeHandler);
    });

    if (this.discountCodeToggler) {
      this.discountCodeToggler.addEventListener('click', () => {
        this.querySelector('[data-rental-booknow="discountCodeGroup"]').classList.remove('d-none');
        this.discountCodeToggler.classList.add('d-none');
      });

      this.discountCodeBtn.addEventListener('click', this.onChangeHandler);
    }

    this.btnBooknow.addEventListener('click', this.instantBookingHandler.bind(this));

    this.dispatchEvent(new Event('widget:init:success'));
  }

  initInquiryModal() {
    const template = document.getElementById('rentalBooknowInquiryModalTemplate');
    const templateContent = template.content;

    document.body.appendChild(templateContent);
    const modalEl = document.getElementById('rentalBooknowInquiryModal');
    this.formModal = smily.modal(modalEl);

    const form = document.getElementById('booknowInquiryForm');
    const alertSuccess = form.querySelector('.alert-success');
    const alertError = form.querySelector('.alert-danger');
    const submitBtn = modalEl.querySelector('[type="submit"]');
    const submitBtnLabel = submitBtn.querySelector('[data-button-label]');
    const submitBtnSpinner = submitBtn.querySelector('[data-button-spinner]');
    const returnBtn = modalEl.querySelector('.modal-footer [data-bs-dismiss="modal"]');

    form.addEventListener('submit', (event) => {
      event.preventDefault();

      alertSuccess.classList.add('d-none');
      alertError.classList.add('d-none');

      submitBtnLabel.classList.add('d-none');
      submitBtnSpinner.classList.remove('d-none');

      if (form.checkValidity()) {
        const endpoint = `${this.apiHost}/api/v2/public/rentals/${this.rentalId}/booknow_v2`;
        const url = this.serializeFormToUrl(endpoint, true);

        // Those params are not supported on this endpoint
        url.searchParams.delete('locale');
        url.searchParams.delete('currency');

        const data = {
          ...Object.fromEntries(url.searchParams),
          ...Object.fromEntries(new FormData(form)),
        };

        url.search = new URLSearchParams(data);

        const showError = () => {
          alertError.classList.remove('d-none');
          this.dispatchEvent(new Event('inquire:submit:error'));
        };

        const fetchOptions = {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'text/html',
          },
        };

        fetch(url, fetchOptions).then((response) => {
          if (response.status === 200) {
            alertSuccess.classList.remove('d-none');

            submitBtn.disabled = true;
            returnBtn.disabled = true;

            this.dispatchEvent(new Event('inquire:submit:success'));

            submitBtnLabel.classList.remove('d-none');
            submitBtnSpinner.classList.add('d-none');
          } else {
            showError();
          }
        }, showError);
      } else {
        event.stopPropagation();
        submitBtnLabel.classList.remove('d-none');
        submitBtnSpinner.classList.add('d-none');
      }

      form.classList.add('was-validated');
    }, false);
  }

  initTrackers() {
    const { rentalId, rentalName } = this.dataset;

    // Track instant booking
    this.addEventListener('instant-booking:submit:success', () => {
      const values = this.form.valuesFromOptions();

      if (this.trackGoogleAnalyticsEvents) {
        smily.trackers.ga('event', 'initiate_checkout', {
          event_category: 'product',
          event_label: `#${rentalId} - ${rentalName}`,
          value: rentalId,
        });
      }

      if (this.trackFacebookEvents) {
        smily.trackers.fbq('track', 'InitiateCheckout', {
          // Lead event currently not supported for hotel content type
          // 'content_ids': rentalId.toString()
          // 'content_type': 'hotel'
          checkin_date: values.checkin,
          checkout_date: values.checkout,
          num_adults: values.adults,
          num_children: values.children,
        });
      }
    });

    // Track inquiry
    this.addEventListener('inquire:submit:success', () => {
      const values = this.form.valuesFromOptions();

      if (this.trackGoogleAnalyticsEvents) {
        smily.trackers.ga('event', 'lead', {
          items: [{
            item_id: `#${rentalId}`,
            item_name: `${rentalName}`,
            item_category: 'product',
          }],
          value: rentalId,
        });
      }

      if (this.trackFacebookEvents) {
        smily.trackers.fbq('track', 'Lead', {
          content_ids: rentalId.toString(),
          content_type: 'hotel',
          checkin_date: values.checkin,
          checkout_date: values.checkout,
          num_adults: values.adults,
          num_children: values.children,
        });
      }
    });
  }

  listenBookableInputsChange() {
    this.bookableInputs.forEach((input) => {
      input.addEventListener('change', this.onChangeHandler);
    });
  }

  unlistenBookableInputsChange() {
    this.bookableInputs.forEach((input) => {
      input.removeEventListener('change', this.onChangeHandler);
    });
  }

  onChangeCb() {
    if (!this.validateValues(true)) return;

    this.unlistenBookableInputsChange();

    const { rentalId } = this.dataset;
    this.loadRates(rentalId)
      .then((response) => response.json())
      .then((data) => {
        const rates = data.rates[0];
        if (rates && Object.keys(rates.errors).length === 0) {
          this.hideErrors();
          this.displayRates(data.rates[0]);
          this.dispatchEvent(new Event('widget:load-rates:success'));
        } else {
          this.hidePriceDetails();
          this.displayErrors(rates.errors);
          this.dispatchEvent(new Event('widget:load-rates:error'));
        }
      })
      .catch((error) => {
        if (smily.isDebug) console.error(error);
        // Reset controller after fetch error
        this.controller = null;
      })
      .finally(() => {
        this.listenBookableInputsChange();
      });
  }

  instantBookingHandler() {
    if (!this.validateValues()) return;

    this.dispatchEvent(new Event('instant-booking:submit:success'));

    const url = this.serializeFormToUrl(this.dataset.booknowUrl, true);

    try {
      const { tracker } = smily.analytics;
      url.searchParams.append('rental_page_visit_id', tracker.rentalPageVisitId(false));
      tracker.track('booknow_widget_clicked', {
        synced_rental_id: this.rentalId,
        rental_page_visit_id: tracker.rentalPageVisitId(false),
      });
    } catch (e) {
      // silence is golden
    }

    window.location = url;
  }

  async loadRates(id) {
    // Cancel previous request
    if (this.controller) this.controller.abort();

    // Create a new controller
    this.controller = new AbortController();

    // Send request with signal option
    const url = this.serializeFormToUrl(`${this.apiHost}/api/v2/public/rentals/${id}/rates.json`);

    this.addPriceDetailsPlaceholder();
    this.priceDetails.classList.remove('d-none');
    this.priceContainer.classList.add('d-none');

    const cache = await caches.open('smily-booknow-cache');
    const cachedResponse = await cache.match(url);

    if (cachedResponse) {
      this.controller = null;
      // Add fake loading time to show the placeholder
      return new Promise((resolve) => {
        setTimeout(() => resolve(cachedResponse), 200);
      });
    }

    const response = await fetch(url.toString(), { signal: this.controller.signal });
    // Reset controller after successful fetch
    this.controller = null;
    // Add response to cache
    cache.put(url, response.clone());
    return response;
  }

  serializeFormToUrl(baseUrl, isIntantBooking = false) {
    const url = new URL(baseUrl);
    const { searchParams } = url;
    const values = this.form.valuesFromOptions(true);

    if (values?.checkin) searchParams.set('start_at', values.checkin);
    if (values?.checkout) searchParams.set('end_at', values.checkout);

    searchParams.set('adults', values.adults ?? 1);
    searchParams.set('children', values.children ?? 0);

    if (isIntantBooking) searchParams.set('rental_id', this.rentalId);

    searchParams.set('encrypted_data', this.dataset.encryptedData);
    searchParams.set('locale', smily.website.lang);
    searchParams.set('currency', smily.website.currency.iso);

    if (this.discountCodeInput?.value) {
      searchParams.set('discount_code', this.discountCodeInput.value);
    }

    this.bookableInputs.forEach((input) => {
      let { name } = input;

      if (!name) return;

      if (isIntantBooking && name.includes('fees_aggregate')) {
        name = name.replace('fees_aggregate', '[bookings_fees_attributes]');
        name = name.replace('quantity', 'times_booked');
      }

      const isChecked = input.checked ? 1 : 0;
      searchParams.set(name, input.type === 'checkbox' ? isChecked : input.value);
    });

    return url;
  }

  validateValues(disableMissingDatesError = false) {
    const values = this.form.valuesFromOptions(true);

    if (values.checkin && values.checkout) return true;

    if (disableMissingDatesError) return false;

    this.displayErrors({ missing_dates: [smily.website.t.rentalBooknow.missingDates] });
    return false;
  }

  displayErrors(errors) {
    this.priceDetails.classList.add('d-none');
    this.priceDetails.replaceChildren();
    this.priceContainer.classList.remove('d-none');
    this.errorElement.classList.remove('d-none');
    let errorsList = '<ul class="list-unstyled m-0 p-0">';

    // eslint-disable-next-line no-unused-vars
    Object.entries(errors).forEach(([_, errorType]) => {
      errorType.forEach((error) => {
        errorsList += `<li>${error}</li>`;
      });
    });

    errorsList += '</ul>';

    this.errorElement.innerHTML = errorsList;
  }

  hideErrors() {
    this.errorElement.classList.add('d-none');
  }

  hidePriceDetails() {
    this.priceDetails.classList.add('d-none');
  }

  addPriceDetailsPlaceholder() {
    this.priceDetails.innerHTML = `
      <div class="card-text placeholder-glow">
        <p class="placeholder col-6"></p><p class="placeholder col-7"></p>
        <p class="col-4 placeholder"></p>
        <p class="placeholder col-5"></p>
        <p class="col-6 placeholder"></p>
        <p class="placeholder col-8"></p>
        <p class="h5 bg-primary col-9 placeholder"></p>
      </div>
    `;
  }

  clonePriceTemplate(el, title, price = null) {
    const template = this.querySelector(`[data-rental-booknow="${el}"]`);
    const element = template.content.cloneNode(true);

    element.querySelector('[data-name="name"]').textContent = title;

    if (price) {
      element.querySelector('[data-name="price"]').textContent = price;
    }

    this.priceDetails.appendChild(element);

    return this.priceDetails.children[this.priceDetails.children.length - 1];
  }

  displayRates(rates) {
    this.priceDetails.replaceChildren();
    this.priceContainer.classList.add('d-none');

    const {
      applied_discount, bookable_fees, fees, final_price, taxes,
    } = rates;
    const t = smily.website.t.rentalBooknow;

    const bookableFeesInputs = (required) => {
      bookable_fees.forEach((fee, index) => {
        if (fee.required !== required) return;

        const isRequired = fee.required ? 'fee-required' : 'fee-bookable-once';
        const template = fee.maximum_bookable ? 'fee-bookable-multiple' : isRequired;

        const booked_fee = fees.find((obj) => obj.rentals_fee_id === fee.id);
        const minValue = fee.required ? 1 : 0;
        const booked_quantity = booked_fee ? booked_fee.quantity : minValue;
        const price = smily.formatPrice(fee.unit_price || fee.rate);

        const el = this.clonePriceTemplate(template, this.tName(fee), price);
        const inputWithId = el.querySelector('input[data-rental-booknow="fee-id"]');
        const inputWithValue = el.querySelector('input[data-rental-booknow="fee-time-booked"]');

        inputWithId.setAttribute('name', `fees_aggregate[${index}][rentals_fee_id]`);
        inputWithId.setAttribute('value', fee.id);
        inputWithValue.setAttribute('name', `fees_aggregate[${index}][quantity]`);
        inputWithValue.setAttribute('value', booked_quantity);

        if (template === 'fee-bookable-once') {
          const switchEl = el.querySelector('[role="switch"]');
          switchEl.setAttribute('id', `rentalBooknowFee_${fee.id}`);
          switchEl.checked = !!booked_quantity;
          switchEl.parentElement.querySelector('label').setAttribute('for', `rentalBooknowFee_${fee.id}`);
        }

        if (fee.maximum_bookable) {
          inputWithValue.setAttribute('min', minValue);
          inputWithValue.setAttribute('max', fee.maximum_bookable);

          const incrementButtons = Array.from(el.querySelectorAll('[name="minus"], [name="plus"]'));
          const change = new Event('change');

          incrementButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
              const val = parseInt(inputWithValue.value) || 0;

              if (event.currentTarget.name === 'plus') {
                if (val < parseInt(inputWithValue.max)) {
                  inputWithValue.value = val + 1;
                  inputWithValue.dispatchEvent(change);
                }
              } else if (val > parseInt(inputWithValue.min)) {
                inputWithValue.value = val - 1;
                inputWithValue.dispatchEvent(change);
              }
            });
          });
        }
      });
    };

    document.querySelectorAll('[data-price-from-booknow]').forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.innerHTML = `
        <span class="text-nowrap fw-bold">${smily.formatPrice(rates.initial_price)}</span><br>
        <span class="text-nowrap">${this.form.querySelector('[type="dates"]').value}</span>
      `;
    });

    // Initial price
    this.clonePriceTemplate('price', t.initialPriceLabel, smily.formatPrice(rates.initial_price));

    // Discount
    if (applied_discount && Number(applied_discount.amount) > 0) {
      this.clonePriceTemplate('price', t.discountLabel, smily.formatPrice(applied_discount.amount));
    }

    // Required fees
    const feesRequired = bookable_fees.filter((fee) => fee.required);

    if (feesRequired.length) {
      this.clonePriceTemplate('title', t.feesRequiredHeader);
      bookableFeesInputs(true);
    }

    // Optional fees
    const feesOptional = bookable_fees.filter((fee) => !fee.required);

    if (feesOptional.length) {
      this.clonePriceTemplate('title', t.feesOptionalHeader);
      bookableFeesInputs(false);
    }

    // Taxes
    if (taxes.length) {
      this.clonePriceTemplate('title', t.taxesHeader);
      taxes.forEach((tax) => {
        this.clonePriceTemplate('price', this.tName(tax), smily.formatPrice(tax.amount));
      });
    }

    // Total price
    this.clonePriceTemplate('final-price', t.finalPriceLabel, smily.formatPrice(final_price));
  }

  tName(o) {
    const { lang } = smily.website;

    if (o.headline && o.headline[lang]) return o.headline[lang];

    return o.name[lang] || o.name.en;
  }
}

customElements.define('rental-booknow', RentalBooknow);

class CalendarForBooknow extends HTMLElement {
  constructor() {
    super();

    const params = new URLSearchParams(document.location.search);

    this.hiddenStart = document.createElement('input');
    this.hiddenEnd = document.createElement('input');

    this.hiddenStart.value = params.get('checkin');
    this.hiddenEnd.value = params.get('checkout');

    // init Calendar Widget
    this.widget = new BookingSyncCalendarWidget({
      apiHost: smily.website.apiHost,
      el: this,
      selectable: true,
      elStartAt: this.hiddenStart,
      elEndAt: this.hiddenEnd,
      lang: smily.website.lang,
      formatDate: '%Y-%m-%d',
      displayMonths: this.displayMonths(),
    });

    window.addEventListener('resize', this.toggleDisplayMonths.bind(this));

    this.widget.on('maps-loaded', () => {
      this.booknow = document.querySelector('rental-booknow');
      this.booknow.inputStart = this.booknow.form.querySelector('[name="checkin"]');
      this.booknow.inputEnd = this.booknow.form.querySelector('[name="checkout"]');

      // bind event on calendar-for-booknow
      this.widget.on('selection-completed', this.onSelectionCompleted.bind(this));
      // bind event on booknow calendar
      this.booknow.inputEnd.addEventListener('change', this.onBooknowSelectionCompleted.bind(this));
    });
  }

  onSelectionCompleted() {
    if (this.isBooknowCalendar()) return;

    this.booknow.inputStart.value = this.hiddenStart.value;
    this.booknow.inputEnd.value = this.hiddenEnd.value;
    this.booknow.inputEnd.dispatchEvent(new Event('input'));
  }

  onBooknowSelectionCompleted() {
    if (!this.isBooknowCalendar()) return;

    this.hiddenStart.value = this.booknow.inputStart.value;
    this.hiddenEnd.value = this.booknow.inputEnd.value;
    this.widget.inputsToValues();
  }

  isBooknowCalendar() {
    return !!this.booknow.form.querySelector('[type="dates"] > .dropdown-toggle[aria-expanded="true"]');
  }

  toggleDisplayMonths() {
    const displayMonths = this.displayMonths();
    if (this.widget.opts.displayMonths !== displayMonths) {
      this.widget.opts.displayMonths = displayMonths;
      this.widget.destroyMonths();
      this.widget.renderMonths(this.widget.yearStart, this.widget.monthStart);
    }
  }

  displayMonths() {
    const breakpoint = smily.mediaBreakPoint.up(this.dataset.breakpointUp || 'xs');
    return breakpoint ? 2 : 1;
  }
}

customElements.define('calendar-for-booknow', CalendarForBooknow);

class ToggleBooknowFullscreen extends HTMLElement {
  constructor() {
    super();

    this.modalTemplate = smily.templates.modal({
      id: 'rentalBooknowModal',
      dialogClass: 'modal-dialog-scrollable modal-fullscreen',
      title: smily.website.t.rentalBooknow.title,
      bodyClass: 'p-0',
      contentClass: 'col-sm-8 mx-auto mt-sm-4',
    });

    this.booknowElement = document.getElementById('booknow');
    this.previousParent = this.booknowElement.parentElement;
    this.previousClass = this.booknowElement.getAttribute('class');

    document.body.insertAdjacentHTML('beforeend', this.modalTemplate);
    this.modalEl = document.getElementById('rentalBooknowModal');
    this.wrapper = this.modalEl.querySelector('[data-content]');
    this.modal = smily.modal(this.modalEl);

    this.toggleModal();
    window.addEventListener('resize', this.toggleModal.bind(this));

    this.addEventListener('click', () => {
      this.modal.show();
    });

    this.modalEl.addEventListener('swiped-right', () => {
      this.modal.hide();
    });
  }

  toggleModal() {
    const toggleBreakpoint = this.toggleBreakpoint();

    if (this.isInModal === toggleBreakpoint) return;

    this.isInModal = toggleBreakpoint;

    if (toggleBreakpoint) {
      this.booknowElement.removeAttribute('class');
      this.wrapper.appendChild(this.booknowElement);
    } else {
      this.modal?.hide();
      this.previousParent.appendChild(this.booknowElement);
      this.booknowElement.setAttribute('class', this.previousClass);
    }
  }

  toggleBreakpoint() {
    return smily.mediaBreakPoint.down(this.dataset.breakpointDown || 'xs');
  }
}

customElements.define('toggle-booknow-fullscreen', ToggleBooknowFullscreen);
